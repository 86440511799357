import { useState } from "react";

import styled from "styled-components";
import { Field, FieldProps, FormikHelpers } from "formik";
import DateInput from "./DateInput";
import eyeIcon from "../../assets/icon-eye.svg";
import eyeOffIcon from "../../assets/icon-eye-off.svg";
import { Checkbox } from "../Checkbox";
import { Toggle } from "./Toggle";
import { DarkButton } from "./Button";
import { InfoTooltip } from "./InfoTooltip";

export const formFieldCss = `
    border-radius: 1rem;
    border-style: none;
    padding: 0.3rem 0.5rem;
    font-size: 1rem;
    font-family: "Roboto";
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--col-214269);
    background-color: #ffffff;

    :focus {
        border-color: #007eff;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
            0 0 0 3px rgba(0, 126, 255, 0.1);
        outline: none;
    }

    :disabled {
        opacity: 0.4;
    }
`;

export const Input = styled(Field)<{ $noHorizontalMargin?: boolean }>`
  ${formFieldCss}

  flex: ${(props) =>
    (() => {
      if (props.$isCheckbox) return "0 0 1rem";
      else if (props.$noHorizontalMargin) return "0 1 100%";
      return "0 1 75%";
    })()};
  margin: ${(props) =>
    (() => {
      if (props.$isCheckbox) return "0 12.5% 0.8rem";
      else if (props.$noHorizontalMargin) return "0 0 0.8rem";
      return "0 auto 0.8rem";
    })()};

  padding: 0 0.5rem;
  height: 2rem;
`;

const InputStandalone = styled(Field)`
  ${formFieldCss}

  padding: 0 0.5rem;
  height: 2rem;
`;

const FieldArrayRow = styled.div`
  margin: 0 auto 0.4rem;
  width: 75%;
  display: flex;
  align-items: center;

  ${InputStandalone} {
    flex: 1;
  }

  ${DarkButton} {
    margin-left: 1rem;
  }
`;

// Input without formik and default form layout styling
export const HTMLInput = styled.input`
  ${formFieldCss}
`;

const PasswordInput = styled(Input)`
  flex: 1;
  margin: 0;
`;

const PasswordShowIcon = styled.img`
  position: absolute;
  right: 0.5rem;
  height: 1.5rem;
  cursor: pointer;
  opacity: 0.6;

  &:hover {
    opacity: 1;
  }
`;

// Similar in dimensions to `Input`, but contains a modified version
// of `Input` with a button
const StyledPasswordRow = styled.div`
  position: relative;
  flex: 0 1 75%;
  align-items: center;
  display: flex;
  margin: 0 auto 0.8rem;
`;

// Similar to input in dimensions and alignment, but only contains text
// Useful for values that are not meant to be modified
export const FormValue = styled.div`
  height: 2rem;
  width: 75%;
  padding: 0 0;
  margin: 0 auto;
  font-size: 1rem;
  font-family: "Roboto";
  color: var(--ice-blue);
`;

const CheckboxLabel = styled.label`
  display: flex;
  color: white;
  font-family: "Roboto";
  font-size: 14px;

  .label-text {
    margin-left: 0.5rem;
  }
`;

const ToggleContainer = styled.div`
  display: flex;
  flex: 0 1 75%;
  margin: 0 auto 0.8rem;
`;

const ToggleText = styled.span<{ color?: string }>`
  margin-left: 1rem;
  color: ${(props) => props.color ?? "initial"};
`;

// Similar in dimensions to `Input`, but contains several checkboxes.
const CheckboxesContainer = styled.div<{ direction: "row" | "column" }>`
  position: relative;
  flex: 0 1 75%;
  display: flex;
  align-items: ${(props) =>
    props.direction === "row" ? "center" : "flex-start"};
  flex-direction: ${(props) => props.direction};
  flex-wrap: ${(props) => (props.direction === "row" ? "wrap" : "nowrap")};
  margin: 0 auto 0.8rem;
  padding: 0.5rem;

  ${CheckboxLabel} {
    margin: ${(props) =>
      props.direction === "row" ? "0.5rem 0 0 0.5rem" : "0 0 0.5rem 0"};
  }
`;

interface FormLabelProps {
  color?: string;
  $noHorizontalMargin?: boolean;
}

export const FormLabel = styled.div<FormLabelProps>`
  font-size: 14px;
  font-weight: 500;
  font-family: "Roboto";
  color: ${(props) => (props.color ? props.color : "#ffffff")};
  height: 16px;
  display: flex;
  align-items: center;
  margin-bottom: 1%;

  ${(props) =>
    props.$noHorizontalMargin
      ? "flex: 0 1 100%;"
      : "margin-left: 12.5%; flex: 0 1 75%;"}

  a {
    color: ${(props) => (props.color ? props.color : "#ffffff")};
  }
`;

export const FormNote = styled(FormLabel)`
  font-size: 0.875rem;
  margin: 0rem 12.5% 1rem 12.5%;
  color: var(--seafoam-blue);
`;

export const Error = styled(FormLabel)`
  color: var(--df-6940);
  height: auto;
`;

export const InputBox = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const InputFeedback = ({
  error,
  noHorizontalMargin,
}: {
  error: string | undefined;
  noHorizontalMargin?: boolean;
}) =>
  error ? (
    <Error $noHorizontalMargin={noHorizontalMargin}>{error}</Error>
  ) : null;

export const FormInput = ({
  type,
  id,
  label,
  labelInfoTooltip,
  whiteInfoTooltip,
  error,
  value,
  onChange,
  onBlur,
  required,
  disabled,
  component,
  componentProps = {},
  options,
  labelColor,
  noHorizontalMargin,
}: {
  type: string;
  label: string | JSX.Element;
  labelInfoTooltip?: string;
  whiteInfoTooltip?: boolean;
  error: string | undefined;
  value: string | number | Date | null | boolean;
  id?: string;
  onChange?: Function;
  onBlur?: Function;
  required?: boolean;
  disabled?: boolean;
  component?: string | typeof DateInput | React.ReactNode;
  componentProps?: { [key: string]: any };
  options?: any[];
  labelColor?: string;
  noHorizontalMargin?: boolean;
}) => (
  <InputBox>
    {label && (
      <FormLabel color={labelColor} $noHorizontalMargin={noHorizontalMargin}>
        {label} {required ? " *" : null}
        {labelInfoTooltip && (
          <InfoTooltip whiteIcon={whiteInfoTooltip} text={labelInfoTooltip} />
        )}
      </FormLabel>
    )}
    <Input
      id={id}
      type={type}
      value={value}
      checked={value}
      onChange={onChange}
      onBlur={onBlur}
      disabled={disabled}
      component={component}
      $isCheckbox={type === "checkbox"}
      $noHorizontalMargin={noHorizontalMargin}
      {...componentProps}
    >
      {options &&
        options.map((option: any) => {
          return (
            <option key={option.value} value={option.value}>
              {option.text}
            </option>
          );
        })}
    </Input>
    <InputFeedback error={error} noHorizontalMargin={noHorizontalMargin} />
  </InputBox>
);

export const FieldArrayFormInput = ({
  type,
  id,
  value,
  onChange,
  onBlur,
  disabled,
  component,
  componentProps = {},
  buttonProps = {},
  options,
}: {
  type: string;
  value: string | number | Date | null | boolean;
  id?: string;
  onChange?: Function;
  onBlur?: Function;
  disabled?: boolean;
  component?: string | typeof DateInput;
  componentProps?: { [key: string]: any };
  buttonProps?: { [key: string]: any };
  options?: any[];
}) => (
  <FieldArrayRow>
    <InputStandalone
      id={id}
      type={type}
      value={value}
      checked={value}
      onChange={onChange}
      onBlur={onBlur}
      disabled={disabled}
      component={component}
      $isCheckbox={type === "checkbox"}
      {...componentProps}
    >
      {options &&
        options.map((option: any) => {
          return (
            <option key={option.value} value={option.value}>
              {option.text}
            </option>
          );
        })}
    </InputStandalone>
    {buttonProps?.text && (
      <DarkButton {...buttonProps}>{buttonProps.text}</DarkButton>
    )}
  </FieldArrayRow>
);

export const FormPasswordInput = ({
  id,
  label,
  error,
  value,
  onChange,
  onBlur,
  required,
  disabled,
  labelColor,
}: {
  id: string;
  label: string | JSX.Element;
  error: string | undefined;
  value: string | number | Date | null;
  onChange?: Function;
  onBlur?: Function;
  required?: boolean;
  disabled?: boolean;
  labelColor?: string;
}) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <InputBox>
      {label && (
        <FormLabel color={labelColor}>
          {label} {required ? " *" : null}
        </FormLabel>
      )}
      <StyledPasswordRow>
        <PasswordInput
          id={id}
          type={!showPassword ? "password" : "text"}
          value={value}
          checked={value}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
        />
        <PasswordShowIcon
          src={showPassword ? eyeOffIcon : eyeIcon}
          onClick={() => setShowPassword(!showPassword)}
        />
      </StyledPasswordRow>
      <InputFeedback error={error} />
    </InputBox>
  );
};

export const FormToggle = ({
  id,
  label,
  selected,
  labelInfoTooltip,
  whiteInfoTooltip,
  onChange,
  labelColor,
  toggleText,
  toggleTextColor,
}: {
  id: string;
  label: string;
  selected: boolean;
  labelInfoTooltip?: string;
  whiteInfoTooltip?: boolean;
  onChange: FormikHelpers<any>["setFieldValue"];
  labelColor?: string;
  toggleText?: string;
  toggleTextColor?: string;
}) => (
  <InputBox>
    <FormLabel color={labelColor}>
      {label}
      {labelInfoTooltip && (
        <InfoTooltip whiteIcon={whiteInfoTooltip} text={labelInfoTooltip} />
      )}
    </FormLabel>
    <ToggleContainer>
      <Toggle
        selected={selected}
        onToggle={(selected) => onChange(id, selected)}
      />
      {toggleText && (
        <ToggleText color={toggleTextColor}>{toggleText}</ToggleText>
      )}
    </ToggleContainer>
  </InputBox>
);

/**
 * A group of checkboxes, the values of which are automagically handled by formik.
 *
 * Formik will automatically add / remove the `value` of each checkbox based on their checked status
 * from the form data field indicated by `id`.
 */
export const CheckboxGroup = ({
  id,
  label,
  required,
  checkboxes,
  error,
  direction = "row",
}: {
  id: string;
  label: string;
  required?: boolean;
  checkboxes: { value: string; text: string }[];
  error?: string;
  direction?: "row" | "column";
}) => {
  return (
    <InputBox>
      <FormLabel>
        {label} {required ? " *" : null}
      </FormLabel>
      <CheckboxesContainer direction={direction}>
        {checkboxes.map(({ value, text }) => (
          <CheckboxLabel key={value}>
            <Field name={id} type="checkbox" value={value}>
              {({ field }: FieldProps) => <Checkbox {...field} />}
            </Field>
            <div className="label-text">{text}</div>
          </CheckboxLabel>
        ))}
      </CheckboxesContainer>
      <InputFeedback error={error} />
    </InputBox>
  );
};

export const ReadonlyFormInput = ({
  label,
  value,
}: {
  label: string;
  value: string;
}) => (
  <InputBox>
    <FormLabel>{label}</FormLabel>
    <FormValue>{value}</FormValue>
  </InputBox>
);
