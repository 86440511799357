import { call, put } from "redux-saga/effects";
import * as surveyApi from "../api/survey";
import * as TYPES from "../constants/actionTypes";
import { NotifySuccess, NotifyError } from "../components/Common/Notify";
import { SurveyActions } from "@shared/types";

/**
 * @export
 * @param {*} action
 */
export function* userSurveyAction(action: any) {
  try {
    const result = yield call(surveyApi.userSurveyAction, action);
    yield put({
      type: TYPES.USER_SURVEY_ACTION_SUCCESS,
      payload: result.data,
    });

    if (action.payload.data.action === SurveyActions.ANSWERED) {
      NotifySuccess(TYPES.USER_SURVEY_ACTION_SUCCESS);
    }
  } catch (err) {
    yield put({ type: TYPES.USER_SURVEY_ACTION_FAILURE });
  }
}

export function* fetchSurveys(action: any) {
  try {
    const result = yield call(surveyApi.fetchSurveys, action);
    yield put({
      type: TYPES.FETCH_SURVEYS_SUCCESS,
      payload: result.data,
    });
  } catch (err) {
    yield put({ type: TYPES.FETCH_SURVEYS_FAILURE });
    NotifyError(TYPES.FETCH_SURVEYS_FAILURE);
  }
}

export function* createSurvey(action: any) {
  try {
    const result = yield call(surveyApi.createSurvey, action);
    yield put({
      type: TYPES.CREATE_SURVEY_SUCCESS,
      payload: result,
    });
    NotifySuccess(TYPES.CREATE_SURVEY_SUCCESS);
  } catch (err) {
    yield put({ type: TYPES.CREATE_SURVEY_FAILURE });
    NotifyError(TYPES.CREATE_SURVEY_FAILURE);
  }
}

export function* updateSurvey(action: any) {
  try {
    const result = yield call(surveyApi.updateSurvey, action);
    yield put({
      type: TYPES.UPDATE_SURVEY_SUCCESS,
      payload: result,
    });
    NotifySuccess(TYPES.UPDATE_SURVEY_SUCCESS);
  } catch (err) {
    yield put({ type: TYPES.UPDATE_SURVEY_FAILURE });
    NotifyError(TYPES.UPDATE_SURVEY_FAILURE);
  }
}

export function* deleteSurvey(action: any) {
  try {
    const result = yield call(surveyApi.deleteSurvey, action);
    yield put({
      type: TYPES.DELETE_SURVEY_SUCCESS,
      payload: result,
    });
    NotifySuccess(TYPES.DELETE_SURVEY_SUCCESS);
  } catch (err) {
    yield put({ type: TYPES.DELETE_SURVEY_FAILURE });
    NotifyError(TYPES.DELETE_SURVEY_FAILURE);
  }
}

export function* fetchSurveyGlobalSettings(action: any) {
  try {
    const result = yield call(surveyApi.fetchSurveyGlobalSettings, action);
    yield put({
      type: TYPES.FETCH_SURVEY_GLOBAL_SETTINGS_SUCCESS,
      payload: result.data,
    });
  } catch (err) {
    yield put({ type: TYPES.FETCH_SURVEY_GLOBAL_SETTINGS_FAILURE });
    NotifyError(TYPES.FETCH_SURVEY_GLOBAL_SETTINGS_FAILURE);
  }
}

export function* updateSurveyGlobalSettings(action: any) {
  try {
    const result = yield call(surveyApi.updateSurveyGlobalSettings, action);
    yield put({
      type: TYPES.UPDATE_SURVEY_GLOBAL_SETTINGS_SUCCESS,
      payload: result.data,
    });
    NotifySuccess(TYPES.UPDATE_SURVEY_GLOBAL_SETTINGS_SUCCESS);
  } catch (err) {
    yield put({ type: TYPES.UPDATE_SURVEY_GLOBAL_SETTINGS_FAILURE });
    NotifyError(TYPES.UPDATE_SURVEY_GLOBAL_SETTINGS_FAILURE);
  }
}

export function* resetSurveyHistory(action: any) {
  try {
    const result = yield call(surveyApi.resetSurveyHistory, action);
    yield put({
      type: TYPES.RESET_SURVEY_HISTORY_SUCCESS,
      payload: result.data,
    });
    NotifySuccess(TYPES.RESET_SURVEY_HISTORY_SUCCESS);
  } catch (err) {
    yield put({ type: TYPES.RESET_SURVEY_HISTORY_FAILURE });
    NotifyError(TYPES.RESET_SURVEY_HISTORY_FAILURE);
  }
}
