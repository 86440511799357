import Axios, { AxiosPromise } from "axios";

/**
 * API endpoints to interact with surveys
 *
 * @export
 * @returns {AxiosPromise}
 */
/*
 * User endpoints
 */
export function userSurveyAction(action: any): AxiosPromise | Object {
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    timeout: 20000,
  };
  const url = `${process.env.REACT_APP_LATEST_API}/surveys/user-action`;
  try {
    return Axios.post(url, action.payload.data, config);
  } catch (error) {
    return error;
  }
}

/*
 * Admin endpoints
 */
export function fetchSurveys(action: any): AxiosPromise | Object {
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    timeout: 20000,
  };
  const url = `${process.env.REACT_APP_LATEST_API}/admin/surveys/`;
  try {
    return Axios.get(url, config);
  } catch (error) {
    return error;
  }
}

export function createSurvey(action: any): AxiosPromise | Object {
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    timeout: 20000,
  };
  const url = `${process.env.REACT_APP_LATEST_API}/admin/surveys/`;
  try {
    return Axios.post(url, action.payload.data, config);
  } catch (error) {
    return error;
  }
}

export function updateSurvey(action: any): AxiosPromise | Object {
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    timeout: 20000,
  };
  const url = `${process.env.REACT_APP_LATEST_API}/admin/surveys/${action.payload.data.id}`;
  try {
    return Axios.patch(url, action.payload.data, config);
  } catch (error) {
    return error;
  }
}

export function deleteSurvey(action: any): AxiosPromise | Object {
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    timeout: 20000,
  };
  const url = `${process.env.REACT_APP_LATEST_API}/admin/surveys/${action.payload.data.id}`;
  try {
    return Axios.delete(url, config);
  } catch (error) {
    return error;
  }
}

export function fetchSurveyGlobalSettings(action: any): AxiosPromise | Object {
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    timeout: 20000,
  };
  const url = `${process.env.REACT_APP_LATEST_API}/admin/surveys/settings`;
  try {
    return Axios.get(url, config);
  } catch (error) {
    return error;
  }
}

export function updateSurveyGlobalSettings(action: any): AxiosPromise | Object {
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    timeout: 20000,
  };
  const url = `${process.env.REACT_APP_LATEST_API}/admin/surveys/settings`;
  try {
    return Axios.post(url, action.payload.data, config);
  } catch (error) {
    return error;
  }
}

export function resetSurveyHistory(action: any): AxiosPromise | Object {
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    timeout: 20000,
  };
  const url = `${process.env.REACT_APP_LATEST_API}/admin/surveys/reset`;
  try {
    return Axios.post(url, {}, config);
  } catch (error) {
    return error;
  }
}
