// TOKEN:
export const FETCH_TOKEN = "FETCH_TOKEN";
export const FETCH_TOKEN_SUCCESS = "FETCH_TOKEN_SUCCESS";
export const FETCH_TOKEN_FAILURE = "FETCH_TOKEN_FAILURE";
export const SET_TOKEN_EXPIRED = "SET_TOKEN_EXPIRED";
export const MFA_FAILURE = "MFA_FAILURE";
export const MFA_REQUIRED = "MFA_REQUIRED";
export const LOCKOUT = "LOCKOUT";

// SERVICE TOOL LOGIN:
export const DO_SERVICE_TOOL_LOGIN = "DO_SERVICE_TOOL_LOGIN";
export const DO_SERVICE_TOOL_LOGIN_SUCCESS = "DO_SERVICE_TOOL_LOGIN_SUCCESS";
export const DO_SERVICE_TOOL_LOGIN_FAILURE = "DO_SERVICE_TOOL_LOGIN_FAILURE";
// DEVICES AND SKIPS
export const FETCH_DEVICES = "FETCH_DEVICES";
export const FETCH_DEVICES_SUCCESS = "FETCH_DEVICES_SUCCESS";
export const FETCH_DEVICES_FAILURE = "FETCH_DEVICES_FAILURE";
export const FILTER_DEVICES_BY_STATUS = "FILTER_DEVICES_BY_STATUS";
export const FILTER_DEVICES_BY_FILL_LEVEL = "FILTER_DEVICES_BY_FILL_LEVEL";
export const FILTER_DEVICES_BY_MACHINE_TYPE = "FILTER_DEVICES_BY_MACHINE_TYPE";
export const CLUSTER_DEVICES_ON_MAP = "CLUSTER_DEVICES_ON_MAP";
export const CLUSTER_DEVICES_ON_MAP_ZOOM = "CLUSTER_DEVICES_ON_MAP_ZOOM";
export const DEVICES_COLUMNS_SELECTED = "DEVICES_COLUMNS_SELECTED";
export const DEVICES_COLUMNS_SELECTED_SUCCESS =
  "DEVICES_COLUMNS_SELECTED_SUCCESS";
export const DEVICES_COLUMNS_SELECTED_FAILURE =
  "DEVICES_COLUMNS_SELECTED_FAILURE";
export const SKIP_COLUMNS_SELECTED = "SKIP_COLUMNS_SELECTED";
export const SKIP_COLUMNS_SELECTED_SUCCESS = "SKIP_COLUMNS_SELECTED_SUCCESS";
export const SKIP_COLUMNS_SELECTED_FAILURE = "SKIP_COLUMNS_SELECTED_FAILURE";
export const SORT_DEVICES = "SORT_DEVICES";
export const SHOW_DEVICE_ROUTE = "SHOW_DEVICE_ROUTE";
export const FETCH_DEVICES_ROUTES = "FETCH_DEVICES_ROUTES";
export const FETCH_DEVICES_ROUTES_SUCCESS = "FETCH_DEVICES_ROUTES_SUCCESS";
export const FETCH_DEVICES_ROUTES_FAILURE = "FETCH_DEVICES_ROUTES_FAILURE";
export const PATCH_DEVICE_DETAIL = "PATCH_DEVICE_DETAIL";
export const PATCH_DEVICE_DETAIL_SUCCESS = "PATCH_DEVICE_DETAIL_SUCCESS";
export const PATCH_DEVICE_SCALE_EMPTYING_LIMIT_SUCCESS =
  "PATCH_DEVICE_SCALE_EMPTYING_LIMIT_SUCCESS";
export const PATCH_DEVICE_SCALE_TOTAL_WEIGHT_SUCCESS =
  "PATCH_DEVICE_SCALE_TOTAL_WEIGHT_SUCCESS";
export const PATCH_DEVICE_FILL_LEVEL_MODE = "PATCH_DEVICE_FILL_LEVEL_MODE";
export const PATCH_DEVICE_FILL_LEVEL_MODE_SUCCESS =
  "PATCH_DEVICE_FILL_LEVEL_MODE_SUCCESS";
export const PATCH_DEVICE_DETAIL_FAILURE = "PATCH_DEVICE_DETAIL_FAILURE";
export const UPDATE_BALE_COUNTER = "UPDATE_BALE_COUNTER";
export const RESET_BALE_COUNTER = "RESET_BALE_COUNTER";
export const RESET_BALE_COUNTER_FAILURE = "RESET_BALE_COUNTER_FAILURE";
export const CHANGE_BALES_READY = "CHANGE_BALES_READY";
export const CHANGE_BALES_READY_FAILURE = "CHANGE_BALES_READY_FAILURE";
// LOGOUT
export const LOGOUT = "LOGOUT";
// SEARCH
export const SEARCH_TERM_UPDATED = "SEARCH_TERM_UPDATED";
export const SEARCH_TERM_ADMIN_UPDATED = "SEARCH_TERM_ADMIN_UPDATED";
// GROUPS
export const FETCH_GROUP_HIERARCHY = "FETCH_GROUP_HIERARCHY";
export const FETCH_GROUP_HIERARCHY_SUCCESS = "FETCH_GROUP_HIERARCHY_SUCCESS";
export const FETCH_GROUP_HIERARCHY_FAILURE = "FETCH_GROUP_HIERARCHY_FAILURE";
export const GROUP_SELECTED = "GROUP_SELECTED";
// USERS
export const FETCH_USER = "FETCH_USER";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_USER_FAILURE = "FETCH_USER_FAILURE";

export const FETCH_USER_FOR_REGISTRATION = "FETCH_USER_FOR_REGISTRATION";
export const FETCH_USER_FOR_REGISTRATION_SUCCESS =
  "FETCH_USER_FOR_REGISTRATION_SUCCESS";
export const FETCH_USER_FOR_REGISTRATION_FAILURE =
  "FETCH_USER_FOR_REGISTRATION_FAILURE";

export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_FAILURE = "REGISTER_USER_FAILURE";

// MAP
export const MAP_PARAMETERS_UPDATED = "MAP_PARAMETERS_UPDATED";

// ADMIN
export const FETCH_USERS_FOR_ADMIN = "FETCH_USERS_FOR_ADMIN";
export const FETCH_USERS_FOR_ADMIN_SUCCESS = "FETCH_USERS_FOR_ADMIN_SUCCESS";
export const FETCH_USERS_FOR_ADMIN_FAILURE = "FETCH_USERS_FOR_ADMIN_FAILURE";

export const FETCH_USER_FOR_ADMIN = "FETCH_USER_FOR_ADMIN";
export const FETCH_USER_FOR_ADMIN_SUCCESS = "FETCH_USER_FOR_ADMIN_SUCCESS";
export const FETCH_USER_FOR_ADMIN_FAILURE = "FETCH_USER_FOR_ADMIN_FAILURE";

export const CREATE_USER_FOR_ADMIN = "CREATE_USER_FOR_ADMIN";
export const CREATE_USER_FOR_ADMIN_SUCCESS = "CREATE_USER_FOR_ADMIN_SUCCESS";
export const CREATE_USER_FOR_ADMIN_FAILURE = "CREATE_USER_FOR_ADMIN_FAILURE";

export const UPDATE_USER_FOR_ADMIN = "UPDATE_USER_FOR_ADMIN";
export const UPDATE_USER_FOR_ADMIN_SUCCESS = "UPDATE_USER_FOR_ADMIN_SUCCESS";
export const UPDATE_USER_FOR_ADMIN_FAILURE = "UPDATE_USER_FOR_ADMIN_FAILURE";
export const UPDATE_USER_FOR_ADMIN_FAILURE_NAME_EXISTS =
  "UPDATE_USER_FOR_ADMIN_FAILURE_NAME_EXISTS";

export const DELETE_USER_FOR_ADMIN = "DELETE_USER_FOR_ADMIN";
export const DELETE_USER_FOR_ADMIN_SUCCESS = "DELETE_USER_FOR_ADMIN_SUCCESS";
export const DELETE_USER_FOR_ADMIN_FAILURE = "DELETE_USER_FOR_ADMIN_FAILURE";

export const DISABLE_USER_FOR_ADMIN = "DISABLE_USER_FOR_ADMIN";
export const DISABLE_USER_FOR_ADMIN_SUCCESS = "DISABLE_USER_FOR_ADMIN_SUCCESS";
export const DISABLE_USER_FOR_ADMIN_FAILURE = "DISABLE_USER_FOR_ADMIN_FAILURE";
export interface DeleteUserAction {
  type: typeof DELETE_USER_FOR_ADMIN;
  payload: {
    token: string;
    userId: number;
  };
}
export interface DisableUserAction {
  type: typeof DISABLE_USER_FOR_ADMIN;
  payload: {
    token: string;
    userId: number;
  };
}

export const SEND_REGISTER_LINK_TO_USER = "SEND_REGISTER_LINK_TO_USER";
export const SEND_REGISTER_LINK_TO_USER_SUCCESS =
  "SEND_REGISTER_LINK_TO_USER_SUCCESS";
export const SEND_REGISTER_LINK_TO_USER_FAILURE =
  "SEND_REGISTER_LINK_TO_USER_FAILURE";

export const ENABLE_MFA_FOR_USER = "ENABLE_MFA_FOR_USER";
export const ENABLE_MFA_FOR_USER_SUCCESS = "ENABLE_MFA_FOR_USER_SUCCESS";
export const ENABLE_MFA_FOR_USER_FAILURE = "ENABLE_MFA_FOR_USER_FAILURE";

export const DISABLE_MFA_FOR_USER = "DISABLE_MFA_FOR_USER";
export const DISABLE_MFA_FOR_USER_SUCCESS = "DISABLE_MFA_FOR_USER_SUCCESS";
export const DISABLE_MFA_FOR_USER_FAILURE = "DISABLE_MFA_FOR_USER_FAILURE";

export const VERIFY_MFA_ACTION = "VERIFY_MFA_ACTION";
export const VERIFY_MFA_ACTION_SUCCESS = "VERIFY_MFA_ACTION_SUCCESS";
export const VERIFY_MFA_ACTION_FAILURE = "VERIFY_MFA_ACTION_FAILURE";

export const ENABLE_USER_MFA_FOR_ADMIN = "ENABLE_USER_MFA_FOR_ADMIN";
export const ENABLE_USER_MFA_FOR_ADMIN_SUCCESS =
  "ENABLE_USER_MFA_FOR_ADMIN_SUCCESS";
export const ENABLE_USER_MFA_FOR_ADMIN_FAILURE =
  "ENABLE_USER_MFA_FOR_ADMIN_FAILURE";
export interface EnableMFAForAdminAction {
  type: typeof ENABLE_USER_MFA_FOR_ADMIN;
  payload: {
    token: string;
    userId: number;
  };
}
export const DISABLE_USER_MFA_FOR_ADMIN = "DISABLE_USER_MFA_FOR_ADMIN";
export const DISABLE_USER_MFA_FOR_ADMIN_SUCCESS =
  "DISABLE_USER_MFA_FOR_ADMIN_SUCCESS";
export const DISABLE_USER_MFA_FOR_ADMIN_FAILURE =
  "DISABLE_USER_MFA_FOR_ADMIN_FAILURE";

export const DELETE_GROUP_FOR_ADMIN = "DELETE_GROUP_FOR_ADMIN";
export const DELETE_GROUP_FOR_ADMIN_SUCCESS = "DELETE_GROUP_FOR_ADMIN_SUCCESS";
export const DELETE_GROUP_FOR_ADMIN_FAILURE = "DELETE_GROUP_FOR_ADMIN_FAILURE";
export interface DeleteGroupAction {
  type: typeof DELETE_GROUP_FOR_ADMIN;
  payload: {
    token: string;
    groupId: number;
  };
}

export const UPDATE_OWNER_GROUP_FOR_DEVICES = "UPDATE_OWNER_GROUP_FOR_DEVICES";
export const UPDATE_OWNER_GROUP_FOR_DEVICES_SUCCESS =
  "UPDATE_OWNER_GROUP_FOR_DEVICES_SUCCESS";
export const UPDATE_OWNER_GROUP_FOR_DEVICES_FAILURE =
  "UPDATE_OWNER_GROUP_FOR_DEVICES_FAILURE";

export const UPDATE_OPERATOR_GROUP_FOR_DEVICES =
  "UPDATE_OPERATOR_GROUP_FOR_DEVICES";
export const UPDATE_OPERATOR_GROUP_FOR_DEVICES_SUCCESS =
  "UPDATE_OPERATOR_GROUP_FOR_DEVICES_SUCCESS";
export const UPDATE_OPERATOR_GROUP_FOR_DEVICES_FAILURE =
  "UPDATE_OPERATOR_GROUP_FOR_DEVICES_FAILURE";

export const GET_ALL_DEVICE_TASKS = "GET_ALL_DEVICE_TASKS";
export const GET_ALL_DEVICE_TASKS_SUCCESS = "GET_ALL_DEVICE_TASKS_SUCCESS";
export const GET_ALL_DEVICE_TASKS_FAILURE = "GET_ALL_DEVICE_TASKS_FAILURE";
export const CREATE_DEVICE_TASKS = "CREATE_DEVICE_TASKS";
export const CREATE_DEVICE_TASKS_SUCCESS = "CREATE_DEVICE_TASKS_SUCCESS";
export const CREATE_DEVICE_TASKS_FAILURE = "CREATE_DEVICE_TASKS_FAILURE";
export const UPDATE_DEVICE_TASK = "UPDATE_DEVICE_TASK";
export const UPDATE_DEVICE_TASK_SUCCESS = "UPDATE_DEVICE_TASK_SUCCESS";
export const UPDATE_DEVICE_TASK_FAILURE = "UPDATE_DEVICE_TASK_FAILURE";
export const DELETE_DEVICE_TASK = "DELETE_DEVICE_TASK";
export const DELETE_DEVICE_TASK_SUCCESS = "DELETE_DEVICE_TASK_SUCCESS";
export const DELETE_DEVICE_TASK_FAILURE = "DELETE_DEVICE_TASK_FAILURE";

export const GET_DEVICE_FILL_LEVELS = "GET_DEVICE_FILL_LEVELS";
export const GET_DEVICE_FILL_LEVELS_SUCCESS = "GET_DEVICE_FILL_LEVELS_SUCCESS";
export const GET_DEVICE_FILL_LEVELS_FAILURE = "GET_DEVICE_FILL_LEVELS_FAILURE";
export const GET_PRESSURE_DATA = "GET_PRESSURE_DATA";
export const GET_PRESSURE_DATA_SUCCESS = "GET_PRESSURE_DATA_SUCCESS";
export const GET_PRESSURE_DATA_FAILURE = "GET_PRESSURE_DATA_FAILURE";

export const GET_ALL_HG_DEVICES = "GET_ALL_HG_DEVICES";
export const GET_ALL_HG_DEVICES_SUCCESS = "GET_ALL_HG_DEVICES_SUCCESS";
export const GET_ALL_HG_DEVICES_FAILURE = "GET_ALL_HG_DEVICES_FAILURE";

export const PATCH_HG_DEVICE = "PATCH_HG_DEVICE";
export const PATCH_HG_DEVICE_SUCCESS = "PATCH_HG_DEVICE_SUCCESS";
export const PATCH_HG_DEVICE_FAILURE = "PATCH_HG_DEVICE_FAILURE";

export const GET_ALL_RETROFIT_LIGHT_DEVICES = "GET_ALL_RETROFIT_LIGHT_DEVICES";
export const GET_ALL_RETROFIT_LIGHT_DEVICES_SUCCESS =
  "GET_ALL_RETROFIT_LIGHT_DEVICES_SUCCESS";
export const GET_ALL_RETROFIT_LIGHT_DEVICES_FAILURE =
  "GET_ALL_RETROFIT_LIGHT_DEVICES_FAILURE";

export const PATCH_RETROFIT_LIGHT_DEVICE = "PATCH_RETROFIT_LIGHT_DEVICE";
export const PATCH_RETROFIT_LIGHT_DEVICE_SUCCESS =
  "PATCH_RETROFIT_LIGHT_DEVICE_SUCCESS";
export const PATCH_RETROFIT_LIGHT_DEVICE_FAILURE =
  "PATCH_RETROFIT_LIGHT_DEVICE_FAILURE";

export const GET_ALL_LICENSES = "GET_ALL_LICENSES";
export const GET_ALL_LICENSES_SUCCESS = "GET_ALL_LICENSES_SUCCESS";
export const GET_ALL_LICENSES_FAILURE = "GET_ALL_LICENSES_FAILURE";
export const GET_GROUP_LICENSES = "GET_GROUP_LICENSES";
export const GET_GROUP_LICENSES_SUCCESS = "GET_GROUP_LICENSES_SUCCESS";
export const GET_GROUP_LICENSES_FAILURE = "GET_GROUP_LICENSES_FAILURE";
export const UPDATE_GROUP_LICENSES = "UPDATE_GROUP_LICENSES";
export const UPDATE_GROUP_LICENSES_SUCCESS = "UPDATE_GROUP_LICENSES_SUCCESS";
export const UPDATE_GROUP_LICENSES_FAILURE = "UPDATE_GROUP_LICENSES_FAILURE";

// RESET/FORGOT PASSWORD
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";

export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";

export const VERIFY_RESET_PASSWORD_TOKEN = "VERIFY_RESET_PASSWORD_TOKEN";
export const VERIFY_RESET_PASSWORD_TOKEN_FAILURE =
  "VERIFY_RESET_PASSWORD_TOKEN_FAILURE";

// USER PROFILE
export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";
export const CHANGE_LANGUAGE_SUCCESS = "CHANGE_LANGUAGE_SUCCESS";
export const CHANGE_LANGUAGE_FAILURE = "CHANGE_LANGUAGE_FAILURE";

// DEVICE HISTORY
export const GET_DEVICE_HISTORY = "GET_DEVICE_HISTORY";
export const GET_DEVICE_HISTORY_SUCCESS = "GET_DEVICE_HISTORY_SUCCESS";
export const GET_DEVICE_HISTORY_FAILURE = "GET_DEVICE_HISTORY_FAILURE";

// SCALE ADMIN
export const ADMIN_ADD_UNASSIGNED_SCALE_TAG = "ADMIN_ADD_UNASSIGNED_SCALE_TAG";
export const ADMIN_ADD_UNASSIGNED_SCALE_TAG_SUCCESS =
  "ADMIN_ADD_UNASSIGNED_SCALE_TAG_SUCCESS";
export const ADMIN_ADD_UNASSIGNED_SCALE_TAG_FAILURE =
  "ADMIN_ADD_UNASSIGNED_SCALE_TAG_FAILURE";
export const FETCH_ADMIN_GROUP_SCALE_TAGS = "FETCH_ADMIN_GROUP_SCALE_TAGS";
export const FETCH_ADMIN_GROUP_SCALE_TAGS_SUCCESS =
  "FETCH_ADMIN_GROUP_SCALE_TAGS_SUCCESS";
export const FETCH_ADMIN_GROUP_SCALE_TAGS_FAILURE =
  "FETCH_ADMIN_GROUP_SCALE_TAGS_FAILURE";
export const ADMIN_REMOVE_SCALE_TAG = "ADMIN_REMOVE_SCALE_TAG";
export const ADMIN_REMOVE_SCALE_TAG_SUCCESS = "ADMIN_REMOVE_SCALE_TAG_SUCCESS";
export const ADMIN_REMOVE_SCALE_TAG_FAILURE = "ADMIN_REMOVE_SCALE_TAG_FAILURE";
export const ADMIN_IMPORT_SCALE_TAGS = "ADMIN_IMPORT_SCALE_TAGS";
export const ADMIN_IMPORT_SCALE_TAGS_SUCCESS =
  "ADMIN_IMPORT_SCALE_TAGS_SUCCESS";
export const ADMIN_IMPORT_SCALE_TAGS_FAILURE =
  "ADMIN_IMPORT_SCALE_TAGS_FAILURE";

export const FETCH_SCALES_SUCCESS = "FETCH_SCALES_SUCCESS";
export const FETCH_SCALES_FAILURE = "FETCH_SCALES_FAILURE";
export const FETCH_SCALES = "FETCH_SCALES";

export const FETCH_DASHBOARD_SUCCESS = "FETCH_DASHBOARD_SUCCESS";
export const FETCH_DASHBOARD_FAILURE = "FETCH_DASHBOARD_FAILURE";
export const FETCH_DASHBOARD = "FETCH_DASHBOARD";

export const ADMIN_UPDATE_SCALE = "ADMIN_UPDATE_SCALE";
export const ADMIN_UPDATE_SCALE_SUCCESS = "ADMIN_UPDATE_SCALE_SUCCESS";
export const ADMIN_UPDATE_SCALE_FAILURE = "ADMIN_UPDATE_SCALE_FAILURE";

export const FETCH_SCALE_FIRMWARES_SUCCESS = "FETCH_SCALE_FIRMWARES_SUCCESS";
export const FETCH_SCALE_FIRMWARES_FAILURE = "FETCH_SCALE_FIRMWARES_FAILURE";
export const FETCH_SCALE_FIRMWARES = "FETCH_SCALE_FIRMWARES";

export const POST_DASHBOARD_WORK_LOG_UPDATE_SUCCESS =
  "POST_DASHBOARD_WORK_LOG_UPDATE_SUCCESS";
export const POST_DASHBOARD_WORK_LOG_UPDATE_FAILURE =
  "POST_DASHBOARD_WORK_LOG_UPDATE_FAILURE";
export const POST_DASHBOARD_WORK_LOG_UPDATE = "POST_DASHBOARD_WORK_LOG_UPDATE";

export const POST_SCALE_UPLOAD_FILE_SUCCESS = "POST_SCALE_UPLOAD_FILE_SUCCESS";
export const POST_SCALE_UPLOAD_FILE_FAILURE = "POST_SCALE_UPLOAD_FILE_FAILURE";
export const POST_SCALE_UPLOAD_FILE = "POST_SCALE_UPLOAD_FILE";

// SCALE TAGS NON-ADMIN
export const FETCH_GROUP_SCALE_TAGS = "FETCH_GROUP_SCALE_TAGS";
export const FETCH_GROUP_SCALE_TAGS_SUCCESS = "FETCH_GROUP_SCALE_TAGS_SUCCESS";
export const FETCH_GROUP_SCALE_TAGS_FAILURE = "FETCH_GROUP_SCALE_TAGS_FAILURE";
export const UPDATE_SCALE_TAG = "UPDATE_SCALE_TAG";
export const UPDATE_SCALE_TAG_SUCCESS = "UPDATE_SCALE_TAG_SUCCESS";
export const UPDATE_SCALE_TAG_FAILURE = "UPDATE_SCALE_TAG_FAILURE";
export const REVOKE_SCALE_TAG = "REVOKE_SCALE_TAG";
export const REVOKE_SCALE_TAG_SUCCESS = "REVOKE_SCALE_TAG_SUCCESS";
export const REVOKE_SCALE_TAG_FAILURE = "REVOKE_SCALE_TAG_FAILURE";
export const UNREVOKE_SCALE_TAG = "UNREVOKE_SCALE_TAG";
export const UNREVOKE_SCALE_TAG_SUCCESS = "UNREVOKE_SCALE_TAG_SUCCESS";
export const UNREVOKE_SCALE_TAG_FAILURE = "UNREVOKE_SCALE_TAG_FAILURE";
export const ASSIGN_SCALE_TAG = "ASSIGN_SCALE_TAG";
export const ASSIGN_SCALE_TAG_SUCCESS = "ASSIGN_SCALE_TAG_SUCCESS";
export const ASSIGN_SCALE_TAG_FAILURE = "ASSIGN_SCALE_TAG_FAILURE";
export const UNASSIGN_SCALE_TAG = "UNASSIGN_SCALE_TAG";
export const UNASSIGN_SCALE_TAG_SUCCESS = "UNASSIGN_SCALE_TAG_SUCCESS";
export const UNASSIGN_SCALE_TAG_FAILURE = "UNASSIGN_SCALE_TAG_FAILURE";
export const FETCH_SCALE_REPORTS = "FETCH_SCALE_REPORTS";
export const FETCH_SCALE_REPORTS_SUCCESS = "FETCH_SCALE_REPORTS_SUCCESS";
export const FETCH_SCALE_REPORTS_FAILURE = "FETCH_SCALE_REPORTS_FAILURE";
export const FETCH_SCALE_RESULTS = "FETCH_SCALE_RESULTS";
export const FETCH_SCALE_RESULTS_SUCCESS = "FETCH_SCALE_RESULTS_SUCCESS";
export const FETCH_SCALE_RESULTS_FAILURE = "FETCH_SCALE_RESULTS_FAILURE";
export const FETCH_SCALE_REPORT_MONTHS = "FETCH_SCALE_REPORT_MONTHS";
export const FETCH_SCALE_REPORT_MONTHS_SUCCESS =
  "FETCH_SCALE_REPORT_MONTHS_SUCCESS";
export const FETCH_SCALE_REPORT_MONTHS_FAILURE =
  "FETCH_SCALE_REPORT_MONTHS_FAILURE";
export const FETCH_SCALE_REPORT_SETTINGS = "FETCH_SCALE_REPORT_SETTINGS";
export const FETCH_SCALE_REPORT_SETTINGS_SUCCESS =
  "FETCH_SCALE_REPORT_SETTINGS_SUCCESS";
export const FETCH_SCALE_REPORT_SETTINGS_FAILURE =
  "FETCH_SCALE_REPORT_SETTINGS_FAILURE";
export const FETCH_SCALE_TOTAL_WEIGHT_LIMIT_NOTIFICATION_SETTINGS =
  "FETCH_SCALE_TOTAL_WEIGHT_LIMIT_NOTIFICATION_SETTINGS";
export const FETCH_SCALE_TOTAL_WEIGHT_LIMIT_NOTIFICATION_SETTINGS_SUCCESS =
  "FETCH_SCALE_TOTAL_WEIGHT_LIMIT_NOTIFICATION_SETTINGS_SUCCESS";
export const FETCH_SCALE_TOTAL_WEIGHT_LIMIT_NOTIFICATION_SETTINGS_FAILURE =
  "FETCH_SCALE_TOTAL_WEIGHT_LIMIT_NOTIFICATION_SETTINGS_FAILURE";
export const UPDATE_SCALE_REPORT_SETTINGS = "UPDATE_SCALE_REPORT_SETTINGS";
export const UPDATE_SCALE_REPORT_SETTINGS_SUCCESS =
  "UPDATE_SCALE_REPORT_SETTINGS_SUCCESS";
export const UPDATE_SCALE_REPORT_SETTINGS_FAILURE =
  "UPDATE_SCALE_REPORT_SETTINGS_FAILURE";
export const UPDATE_SCALE_TOTAL_WEIGHT_LIMIT_NOTIFICATION_SETTINGS =
  "UPDATE_SCALE_TOTAL_WEIGHT_LIMIT_NOTIFICATION_SETTINGS";
export const UPDATE_SCALE_TOTAL_WEIGHT_LIMIT_NOTIFICATION_SETTINGS_SUCCESS =
  "UPDATE_SCALE_TOTAL_WEIGHT_LIMIT_NOTIFICATION_SETTINGS_SUCCESS";
export const UPDATE_SCALE_TOTAL_WEIGHT_LIMIT_NOTIFICATION_SETTINGS_FAILURE =
  "UPDATE_SCALE_TOTAL_WEIGHT_LIMIT_NOTIFICATION_SETTINGS_FAILURE";
export const RESEND_SCALE_REPORT = "RESEND_SCALE_REPORT";
export const RESEND_SCALE_REPORT_SUCCESS = "RESEND_SCALE_REPORT_SUCCESS";
export const RESEND_SCALE_REPORT_FAILURE = "RESEND_SCALE_REPORT_FAILURE";

export const FETCH_USER_GROUPS_FOR_GROUP = "FETCH_USER_GROUPS_FOR_GROUP";
export const FETCH_USER_GROUPS_FOR_GROUP_SUCCESS =
  "FETCH_USER_GROUPS_FOR_GROUP_SUCCESS";
export const FETCH_USER_GROUPS_FOR_GROUP_FAILURE =
  "FETCH_USER_GROUPS_FOR_GROUP_FAILURE";
export const CREATE_SCALE_USER_GROUP = "CREATE_SCALE_USER_GROUP";
export const CREATE_SCALE_USER_GROUP_SUCCESS =
  "CREATE_SCALE_USER_GROUP_SUCCESS";
export const CREATE_SCALE_USER_GROUP_FAILURE =
  "CREATE_SCALE_USER_GROUP_FAILURE";
export const DELETE_SCALE_USER_GROUP = "DELETE_SCALE_USER_GROUP";
export const DELETE_SCALE_USER_GROUP_SUCCESS =
  "DELETE_SCALE_USER_GROUP_SUCCESS";
export const DELETE_SCALE_USER_GROUP_FAILURE =
  "DELETE_SCALE_USER_GROUP_FAILURE";
export const UPDATE_SCALE_USER_GROUP = "UPDATE_SCALE_USER_GROUP";
export const UPDATE_SCALE_USER_GROUP_SUCCESS =
  "UPDATE_SCALE_USER_GROUP_SUCCESS";
export const UPDATE_SCALE_USER_GROUP_FAILURE =
  "UPDATE_SCALE_USER_GROUP_FAILURE";
export const FETCH_SCALE_EVENTS_SUCCESS = "FETCH_SCALE_EVENTS_SUCCESS";
export const FETCH_SCALE_EVENTS_FAILURE = "FETCH_SCALE_EVENTS_FAILURE";
export const FETCH_SCALE_EVENTS = "FETCH_SCALE_EVENTS";
export const GENERATE_SCALE_REPORT_FAILURE = "GENERATE_SCALE_REPORT_FAILURE";

export const FETCH_ANOMALIES = "FETCH_ANOMALIES";
export const FETCH_ANOMALIES_SUCCESS = "FETCH_ANOMALIES_SUCCESS";
export const FETCH_ANOMALIES_FAILURE = "FETCH_ANOMALIES_FAILURE";
export const UPDATE_ANOMALIES = "UPDATE_ANOMALIES";
export const UPDATE_ANOMALIES_SUCCESS = "UPDATE_ANOMALIES_SUCCESS";
export const UPDATE_ANOMALIES_FAILURE = "UPDATE_ANOMALIES_FAILURE";

export const USER_SURVEY_ACTION = "USER_SURVEY_ACTION";
export const USER_SURVEY_ACTION_SUCCESS = "USER_SURVEY_ACTION_SUCCESS";
export const USER_SURVEY_ACTION_FAILURE = "USER_SURVEY_ACTION_FAILURE";

export const FETCH_SURVEYS = "FETCH_SURVEYS";
export const FETCH_SURVEYS_SUCCESS = "FETCH_SURVEYS_SUCCESS";
export const FETCH_SURVEYS_FAILURE = "FETCH_SURVEYS_FAILURE";
export const FETCH_SURVEY_GLOBAL_SETTINGS = "FETCH_SURVEY_GLOBAL_SETTINGS";
export const FETCH_SURVEY_GLOBAL_SETTINGS_SUCCESS =
  "FETCH_SURVEY_GLOBAL_SETTINGS_SUCCESS";
export const FETCH_SURVEY_GLOBAL_SETTINGS_FAILURE =
  "FETCH_SURVEY_GLOBAL_SETTINGS_FAILURE";
export const UPDATE_SURVEY_GLOBAL_SETTINGS = "UPDATE_SURVEY_GLOBAL_SETTINGS";
export const UPDATE_SURVEY_GLOBAL_SETTINGS_SUCCESS =
  "UPDATE_SURVEY_GLOBAL_SETTINGS_SUCCESS";
export const UPDATE_SURVEY_GLOBAL_SETTINGS_FAILURE =
  "UPDATE_SURVEY_GLOBAL_SETTINGS_FAILURE";
export const CREATE_SURVEY = "CREATE_SURVEY";
export const CREATE_SURVEY_SUCCESS = "CREATE_SURVEY_SUCCESS";
export const CREATE_SURVEY_FAILURE = "CREATE_SURVEY_FAILURE";
export const UPDATE_SURVEY = "UPDATE_SURVEY";
export const UPDATE_SURVEY_SUCCESS = "UPDATE_SURVEY_SUCCESS";
export const UPDATE_SURVEY_FAILURE = "UPDATE_SURVEY_FAILURE";
export const DELETE_SURVEY = "DELETE_SURVEY";
export const DELETE_SURVEY_SUCCESS = "DELETE_SURVEY_SUCCESS";
export const DELETE_SURVEY_FAILURE = "DELETE_SURVEY_FAILURE";
export const RESET_SURVEY_HISTORY = "RESET_SURVEY_HISTORY";
export const RESET_SURVEY_HISTORY_SUCCESS = "RESET_SURVEY_HISTORY_SUCCESS";
export const RESET_SURVEY_HISTORY_FAILURE = "RESET_SURVEY_HISTORY_FAILURE";
