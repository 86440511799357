import { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import styled from "styled-components";
import { format } from "date-fns";
import * as TYPES from "../../../constants/actionTypes";
import { Survey } from "../../../interfaces/types";
import { getSurveyCategoryNameById } from "../../../utils/utils";
import { SurveyType } from "@shared/types";

import { Modal } from "../../../components/Common/Modal";
import { SurveySettingsForm } from "./SurveySettingsForm";
import { SurveyForm } from "./SurveyForm";
import {
  ConfirmButtonLarge,
  PrimaryButtonLarge,
} from "../../../components/Common/Button";

import icon_edit from "../../../assets/icon-edit.svg";

const SurveysPage = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 200px; // Add some headroom (tailroom?) to the page
`;
const PageTitle = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 20px;

  > div {
    margin-top: 20px;
  }
`;
const SurveyPageSection = styled.div`
  margin: 20px;
  padding: 10px;
  border: 2px solid #ccc;
`;
const SurveyPageSectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;
const SurveyCardsContainer = styled.div`
  display: flex;
  width: 100vw;
  flex-wrap: wrap;
  gap: 10px;
`;
const SurveyCardComponent = styled.div`
  display: flex;
  flex-direction: column;
  color: black;

  background: #ececec;
  margin-bottom: 10px;
  padding: 5px;

  height: 200px;
  width: 200px;
`;
const SurveyCardTitleContainer = styled.div`
  display: flex;
`;
const SurveyCardTitle = styled.div<{ active: boolean }>`
  width: 100%;
  font-weight: ${(props) => (props.active ? "700" : "400")};
  color: ${(props) => (props.active ? "black" : "#999")};
`;
const SurveyCardEdit = styled.button`
  background: none;
  border: none;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  cursor: pointer;
`;
const SurveyCardBody = styled.div`
  font-size: 0.8em;
  color: #999;
  flex-grow: 1;
`;
const SurveyActive = styled.div<{ active: boolean }>`
  color: ${(props) => (props.active ? "var(--seafoam-blue)" : "#f66")};
`;
const SurveyCardStatsContainer = styled.div`
  font-size: 0.6em;
`;
const SurveyCardStat = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
`;
const SurveyCardStatTitle = styled.div`
  width: 66%;
`;
const SurveyCardStatValue = styled.div`
  text-align: left;
  width: 33%;
`;
const SurveyPageButtons = styled.div`
  display: flex;
  gap: 20px;
`;
const SurveyResetModal = styled.div`
  display: flex;
  flex-direction: column;
`;

const mapDispatchToProps = (dispatch: any) => {
  return {
    fetchSurveys: (token: string) => {
      dispatch({
        type: TYPES.FETCH_SURVEYS,
        payload: {
          token: token,
        },
      });
    },
    createSurvey: (token: string, payload: any) => {
      dispatch({
        type: TYPES.CREATE_SURVEY,
        payload: {
          token: token,
          data: payload,
        },
      });
    },
    updateSurvey: (token: string, payload: any) => {
      dispatch({
        type: TYPES.UPDATE_SURVEY,
        payload: {
          token: token,
          data: payload,
        },
      });
    },
    deleteSurvey: (token: string, payload: any) => {
      dispatch({
        type: TYPES.DELETE_SURVEY,
        payload: {
          token: token,
          data: payload,
        },
      });
    },
    fetchSurveyGlobalSettings: (token: string) => {
      dispatch({
        type: TYPES.FETCH_SURVEY_GLOBAL_SETTINGS,
        payload: {
          token: token,
        },
      });
    },
    updateSurveyGlobalSettings: (token: string, payload: any) => {
      dispatch({
        type: TYPES.UPDATE_SURVEY_GLOBAL_SETTINGS,
        payload: {
          token: token,
          data: payload,
        },
      });
    },
    resetSurveyHistory: (token: string) => {
      dispatch({
        type: TYPES.RESET_SURVEY_HISTORY,
        payload: {
          token: token,
        },
      });
    },
  };
};

const mapStateToProps = (state: any) => {
  return {
    surveys: state.admin.surveys.data,
    globalSettings: state.admin.surveys.globalSettings,
    isLoaded: state.admin.isLoaded,
    token: state.token.key,
  };
};

interface SurveyCardProps {
  survey: Survey;
  title: string;
  allowDelete?: boolean;
  onEdit?: () => void;
}
const SurveyCard = (props: SurveyCardProps) => {
  const completionRatio =
    props.survey.timesTriggered > 0
      ? (
          (props.survey.timesCompleted / props.survey.timesTriggered) *
          100
        ).toFixed(1)
      : "–";
  const dismissalRatio =
    props.survey.timesTriggered > 0
      ? (
          (props.survey.timesDismissed / props.survey.timesTriggered) *
          100
        ).toFixed(1)
      : "–";
  const openedRatio =
    props.survey.timesTriggered > 0
      ? (
          (props.survey.timesOpened / props.survey.timesTriggered) *
          100
        ).toFixed(1)
      : "–";
  return (
    <SurveyCardComponent>
      <SurveyCardTitleContainer>
        <SurveyCardTitle active={props.survey.active}>
          {props.title}
        </SurveyCardTitle>
        <SurveyCardEdit onClick={props.onEdit}>
          <img src={icon_edit} alt="Edit survey" height="24" />
        </SurveyCardEdit>
      </SurveyCardTitleContainer>
      <SurveyCardBody>
        <SurveyActive active={props.survey.active}>
          Survey {props.survey.active ? "active" : "inactive"}
        </SurveyActive>
        {props.survey.key ? (
          <>
            key: <strong>{props.survey.key}</strong>
          </>
        ) : (
          <strong>Warning: Survey has no key</strong>
        )}
        <div>
          Created {format(new Date(props.survey.createdAt), "HH:mm yyyy-MM-dd")}
        </div>
      </SurveyCardBody>
      <SurveyCardStatsContainer>
        <SurveyCardStat>
          <SurveyCardStatTitle>Times triggered:</SurveyCardStatTitle>
          <SurveyCardStatValue>
            <strong>{props.survey.timesTriggered}</strong>
          </SurveyCardStatValue>
        </SurveyCardStat>
        <SurveyCardStat>
          <SurveyCardStatTitle>Times opened:</SurveyCardStatTitle>
          <SurveyCardStatValue>
            <strong>{props.survey.timesOpened}</strong> ({openedRatio}%)
          </SurveyCardStatValue>
        </SurveyCardStat>
        <SurveyCardStat>
          <SurveyCardStatTitle>Times dismissed:</SurveyCardStatTitle>
          <SurveyCardStatValue>
            <strong>{props.survey.timesDismissed}</strong> ({dismissalRatio}%)
          </SurveyCardStatValue>
        </SurveyCardStat>
        <SurveyCardStat>
          <SurveyCardStatTitle>Times completed:</SurveyCardStatTitle>
          <SurveyCardStatValue>
            <strong>{props.survey.timesCompleted}</strong> ({completionRatio}%)
          </SurveyCardStatValue>
        </SurveyCardStat>
      </SurveyCardStatsContainer>
    </SurveyCardComponent>
  );
};

interface SurveysAdminPageProps {
  surveys: Survey[];
  globalSettings: any;
  createSurvey(token: string, data: any): void;
  updateSurvey(token: string, data: any): void;
  deleteSurvey(token: string, data: any): void;
  fetchSurveys(token: string): void;
  fetchSurveyGlobalSettings(token: string): void;
  updateSurveyGlobalSettings(token: string, data: any): void;
  resetSurveyHistory(token: string): void;
  isLoaded: boolean;
  token: any;
}
const SurveysAdminPageConnected = (props: SurveysAdminPageProps) => {
  const token = useSelector((state: any) => state.token.key);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showResetModal, setShowResetModal] = useState<boolean>(false);
  const [selectedSurvey, setSelectedSurvey] = useState<Survey | null>(null);
  const [settingsLoaded, setSettingsLoaded] = useState<boolean>(false);
  const {
    surveys,
    globalSettings,
    fetchSurveys,
    fetchSurveyGlobalSettings,
    updateSurveyGlobalSettings,
    createSurvey,
    updateSurvey,
    deleteSurvey,
    resetSurveyHistory,
  } = props;

  useEffect(() => {
    fetchSurveys(token);
    fetchSurveyGlobalSettings(token);
  }, [fetchSurveys, fetchSurveyGlobalSettings, token]);

  useEffect(() => {
    // Bit of an ugly hack to wait for the global settings to load.
    // TODO: Would make more sense for settingsLoaded to live in the store instead
    setSettingsLoaded(Object.keys(globalSettings).length > 0);
  }, [globalSettings]);

  if (!props.isLoaded) {
    return <div>Loading...</div>;
  }

  const handleUpdateSettings = (values: any) => {
    updateSurveyGlobalSettings(token, values);
  };

  const handleCreateSurvey = (values: any) => {
    createSurvey(token, values);
    setSelectedSurvey(null);
    setShowEditModal(false);
  };

  const handleUpdateSurvey = (values: any) => {
    updateSurvey(token, values);
    setSelectedSurvey(null);
    setShowEditModal(false);
  };

  const handleDeleteSurvey = (id: number) => {
    deleteSurvey(token, { id });
    setSelectedSurvey(null);
    setShowEditModal(false);
  };

  const handleResetSurveyHistory = () => {
    resetSurveyHistory(token);
    setShowResetModal(false);
  };

  const handleOpenEditModal = (survey: Survey) => {
    setShowEditModal(true);
    setSelectedSurvey(survey);
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false);
    setSelectedSurvey(null);
  };

  const handleCloseResetModal = () => {
    setShowResetModal(false);
  };

  const engagementSurveyTypes = [
    SurveyType.SURVEY_ONBOARDING,
    SurveyType.SURVEY_ENGAGEMENT_NPS,
    SurveyType.SURVEY_ENGAGEMENT_CSAT,
  ];
  const actionSurveys = surveys.filter(
    (s) => s.surveyType === SurveyType.SURVEY_ACTION_BASED
  );

  return (
    <SurveysPage>
      <PageTitle>
        <h1>SMART surveys</h1>
        <div>
          <PrimaryButtonLarge onClick={() => setShowResetModal(true)}>
            Reset user survey history
          </PrimaryButtonLarge>
        </div>
      </PageTitle>
      <SurveyPageSection>
        {settingsLoaded ? (
          <SurveySettingsForm
            initialValues={globalSettings}
            onSubmit={handleUpdateSettings}
          />
        ) : (
          <>Loading settings...</>
        )}
      </SurveyPageSection>

      <SurveyPageSection>
        <h3>Engagement surveys</h3>
        <SurveyCardsContainer>
          {engagementSurveyTypes.map((t) => {
            // There will only ever be one Engagement survey per type, so "find" should do the trick?
            // TODO: Restrict creation of more than one in the database
            const _survey = surveys.find((s) => s.surveyType === t);
            if (!_survey) {
              return (
                <SurveyCardComponent key={t}>
                  No {getSurveyCategoryNameById(t)} found.
                </SurveyCardComponent>
              );
            }
            return (
              <SurveyCard
                survey={_survey}
                title={getSurveyCategoryNameById(t)}
                onEdit={() => handleOpenEditModal(_survey)}
                key={_survey.id}
              />
            );
          })}
        </SurveyCardsContainer>
      </SurveyPageSection>

      <SurveyPageSection>
        <SurveyPageSectionHeader>
          <h3>Action-based surveys</h3>
          <ConfirmButtonLarge
            onClick={() => setShowCreateModal(true)}
            style={{ height: "fit-content" }}
          >
            Create Survey
          </ConfirmButtonLarge>
        </SurveyPageSectionHeader>

        <SurveyCardsContainer>
          {actionSurveys.length > 0 ? (
            actionSurveys.map((s) => (
              <SurveyCard
                survey={s}
                onEdit={() => handleOpenEditModal(s)}
                title={s.name || "No title"}
                key={s.id}
              ></SurveyCard>
            ))
          ) : (
            <div>No action-based surveys found.</div>
          )}
        </SurveyCardsContainer>
      </SurveyPageSection>

      <Modal
        isOpen={showResetModal}
        handleClose={handleCloseResetModal}
        content={
          <SurveyResetModal>
            <h3>Do you want to reset your survey history?</h3>
            <SurveyPageButtons>
              <ConfirmButtonLarge onClick={handleResetSurveyHistory}>
                Reset
              </ConfirmButtonLarge>
              <PrimaryButtonLarge onClick={() => setShowResetModal(false)}>
                Cancel
              </PrimaryButtonLarge>
            </SurveyPageButtons>
          </SurveyResetModal>
        }
        title="Reset survey history"
      />
      <Modal
        height={"calc(100% - 20px)"}
        isOpen={showCreateModal}
        handleClose={() => setShowCreateModal(false)}
        content={
          <SurveyForm
            onSubmit={handleCreateSurvey}
            onCancel={() => setShowCreateModal(false)}
            surveyGlobalSettings={globalSettings}
          />
        }
        title="Create Survey"
      />
      <Modal
        height={"calc(100% - 20px)"}
        isOpen={showEditModal}
        handleClose={handleCloseEditModal}
        content={
          <SurveyForm
            initialValues={selectedSurvey}
            onSubmit={handleUpdateSurvey}
            onCancel={() => setShowEditModal(false)}
            // Can only delete Action-surveys
            onDelete={
              selectedSurvey?.surveyType === SurveyType.SURVEY_ACTION_BASED
                ? handleDeleteSurvey
                : undefined
            }
            surveyGlobalSettings={globalSettings}
          />
        }
        title="Edit survey"
      />
    </SurveysPage>
  );
};

export const SurveysAdminPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(SurveysAdminPageConnected);
