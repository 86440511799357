import styled from "styled-components";

interface PopupProps {
  isOpen: boolean;
  handleClose: () => void;
  content: JSX.Element;
  title?: JSX.Element | string;
  navigation?: JSX.Element;
  contentPadding?: number;
  contentScroll?: boolean;
  fullScreenEnabled?: boolean;
  width?: string;
  height?: string;
}

const PopupComponent = styled.div`
  z-index: 999;
  min-width: 500px;
  border-radius: 25px;

  position: fixed;
  right: 60px;
  bottom: 120px;

  background: white;
  box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.5);
`;
const CloseButton = styled.div`
  cursor: pointer;
  position: absolute;
  padding: 5px 10px;
  top: 10px;
  right: 10px;
`;
const Content = styled.div`
  padding: 25px 0;
`;
const Header = styled.div`
  padding: 25px;
  min-height: 1em;
`;

export function Popup(props: React.PropsWithChildren<PopupProps>) {
  if (!props.isOpen) return null;
  return (
    <PopupComponent>
      <Header>
        {props.title}
        <CloseButton onClick={props.handleClose}>×</CloseButton>
      </Header>
      <Content>{props.children}</Content>
    </PopupComponent>
  );
}
