import { useEffect, useState } from "react";
import styled from "styled-components";

interface SurveyIntervalInputProps {
  id: string;
  onChange: Function;
  value: string;
  disabled?: boolean;
}

const SurveyInputIntervalContainer = styled.div`
  border-radius: 8px;
  border: 1px solid #ccc;
  background: white;
  width: 200px;
`;
const TimeInput = styled.input`
  border: none;
  border-right: 1px solid #ccc;
  background: none;
  padding: 6px 4px;
  text-align: center;
  width: 33%;
  height: 100%;
`;
const UnitInput = styled.select`
  width: 67%;
  background: none;
  padding: 6px 4px;
  border: none;
  cursor: pointer;
  height: 100%;
`;

export const SurveyIntervalInput = (props: SurveyIntervalInputProps) => {
  const parseSurveyInterval = (interval: string) => {
    try {
      const splitInterval = interval.split(" ");
      return {
        time: parseInt(splitInterval[0]),
        unit: splitInterval[1],
      };
    } catch (error) {
      console.warn(
        `Warning: Invalid interval "${interval}" provided, defaulting to 3 days.`
      );
      return {
        time: 3,
        unit: "days",
      };
    }
  };

  const initialValue = parseSurveyInterval(props.value);

  const [time, setTime] = useState<number>(initialValue.time);
  const [unit, setUnit] = useState<string>(initialValue.unit);
  const { onChange } = props;

  useEffect(() => {
    if (!unit || !time) return;
    if (unit !== "days" && unit !== "sessions") {
      console.error(`Invalid interval unit ${unit}!`);
      return;
    }

    onChange(`${time} ${unit}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time, unit]);

  if (props.disabled) {
    return (
      <span>
        {time} {unit}
      </span>
    );
  }

  return (
    <SurveyInputIntervalContainer>
      <TimeInput
        value={time}
        onChange={(e) => setTime(parseInt(e.target.value))}
        type="number"
      />
      <UnitInput value={unit} onChange={(e) => setUnit(e.target.value)}>
        <option value={"days"}>days</option>
        <option value={"sessions"}>sessions</option>
      </UnitInput>
    </SurveyInputIntervalContainer>
  );
};

export default SurveyIntervalInput;
