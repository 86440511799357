import { useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import styled from "styled-components";

import { FormWrapper } from "../../../components/Common/FormWrapper";

import {
  ConfirmButtonLarge,
  PrimaryButtonLarge,
} from "../../../components/Common/Button";
import SurveyIntervalInput from "./SurveyIntervalInput";

const mapStateToProps = (state: any) => {
  return {
    surveyGlobalSettings: state.admin.surveys.globalSettings,
  };
};

type SurveySettingsFormProps = {
  onSubmit: (values: any) => void;
  initialValues: any;
  surveyGlobalSettings: any;
};

const SettingsFormWrapper = styled(FormWrapper)`
  background: #f6f6f6;
  padding: 10px;
`;
const SettingsFieldLabel = styled.label`
  font-weight: 400;
  margin-bottom: 10px;
  display: inline;
`;

const SettingsFormFields = styled.div`
  max-height: 600px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;

const SettingsFormInputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  gap: 10px;
  margin-bottom: 10px;

  :has(textarea) {
    flex-direction: column;
  }
`;
const SettingsFormButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
`;
const SurveySettingsFormComponent = (props: SurveySettingsFormProps) => {
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState(false);

  const handleSubmit = (values: any) => {
    props.onSubmit(values);
  };

  return (
    <Formik initialValues={props.initialValues} onSubmit={handleSubmit}>
      {(formikProps) => {
        const { values, setFieldValue } = formikProps;
        return (
          <SettingsFormWrapper>
            <h3>Settings</h3>
            <SettingsFormFields>
              <SettingsFormInputContainer>
                <SettingsFieldLabel>
                  {t(`surveys.admin.settings.disableAllSurveys`)}
                </SettingsFieldLabel>
                <input
                  type="checkbox"
                  id={"disableAllSurveys"}
                  // This is stored as a string in the DB, which is a bit wonky
                  onChange={(e) =>
                    setFieldValue(
                      "disableAllSurveys",
                      e.target.checked.toString()
                    )
                  }
                  checked={values["disableAllSurveys"] === "true"}
                  disabled={!isEditing}
                />
              </SettingsFormInputContainer>

              <SettingsFormInputContainer>
                <SettingsFieldLabel>
                  {t(`surveys.admin.settings.globalCooldownTime`)}
                </SettingsFieldLabel>
                <SurveyIntervalInput
                  id={"globalCooldownTime"}
                  onChange={(e: string) =>
                    setFieldValue("globalCooldownTime", e)
                  }
                  value={values["globalCooldownTime"]}
                  disabled={!isEditing}
                />
              </SettingsFormInputContainer>

              <SettingsFormInputContainer>
                <SettingsFieldLabel>
                  {t(`surveys.admin.settings.noninteractionExpiry`)}
                </SettingsFieldLabel>
                <SurveyIntervalInput
                  id={"disableAllSurveys"}
                  value={values["noninteractionExpiry"]}
                  onChange={(e: string) =>
                    setFieldValue("noninteractionExpiry", e)
                  }
                  disabled={!isEditing}
                />
              </SettingsFormInputContainer>
            </SettingsFormFields>
            <SettingsFormButtons>
              {isEditing ? (
                <>
                  <ConfirmButtonLarge type="submit">Save</ConfirmButtonLarge>
                  <PrimaryButtonLarge onClick={() => setIsEditing(false)}>
                    Cancel
                  </PrimaryButtonLarge>
                </>
              ) : (
                <PrimaryButtonLarge onClick={() => setIsEditing(true)}>
                  Edit
                </PrimaryButtonLarge>
              )}
            </SettingsFormButtons>
          </SettingsFormWrapper>
        );
      }}
    </Formik>
  );
};

export const SurveySettingsForm = connect(mapStateToProps)(
  SurveySettingsFormComponent
);
