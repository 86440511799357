import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";
import UsersTable from "./UsersTable";
import { Page } from "../Page";
import { Header } from "../../components/Header";
import { CreateUserForm, UpdateUserForm } from "./UsersForm";
import { NavTab } from "../../components/Common/Tab";
import { v4 as uuidv4 } from "uuid";
import { Groups } from "./Groups";
import { GroupCreateForm, GroupUpdateForm } from "./GroupForm";
import { ScaleKeys } from "./Scale/ScaleKeys";
import { ScaleFirmwares } from "./Scale/ScaleFirmwares";
import { Navigation } from "../../components/Common/Navigation";
import { Devices } from "./Devices/Devices";
import { ScaleMinimumWeights } from "./Scale/ScaleMinimumWeights";
import { DeviceTasks } from "./DeviceTasks/DeviceTasks";
import { Licenses } from "./Licenses/Licenses";
import { GroupLicensesForm } from "./Licenses/GroupLicensesForm";
import { DevicePressureData } from "./DevicePressureData/DevicePressureData";
import { DeviceManagement } from "./DeviceManagement/DeviceManagement";
import { EditHGDeviceForm } from "./DeviceManagement/EditHGDeviceForm";
import { Dashboard } from "./Dashboard";
import { PressureAnomalies } from "./PressureAnomalies/PressureAnomalies";
import { EditRetrofitLightDeviceForm } from "./DeviceManagement/EditRetrofitLightDeviceForm";
import { SurveysAdminPage } from "./Surveys/SurveysAdminPage";

const Admin = () => {
  const match = useRouteMatch();
  const path = match && match.path;

  const tabs = [
    {
      path: `${path}/users`,
      title: "USERS",
    },
    {
      path: `${path}/groups`,
      title: "GROUPS",
    },
    {
      path: `${path}/licenses`,
      title: "LICENSES",
    },
    {
      path: `${path}/scale/keys`,
      title: "SCALE KEYS",
    },
    {
      path: `${path}/scale/minimum-weight`,
      title: "SCALE MINIMUM WEIGHTS",
    },
    {
      path: `${path}/scale/firmwares`,
      title: "SCALE FIRMWARE UPDATE",
    },
    {
      path: `${path}/devices`,
      title: "DEVICES",
      exact: true,
    },
    {
      path: `${path}/devices/tasks`,
      title: "DEVICE TASKS",
    },
    {
      path: `${path}/devices/pressure-data`,
      title: "DEVICE PRESSURE DATA",
    },
    {
      path: `${path}/devices/device-management`,
      title: "DEVICE MANAGEMENT",
    },
    {
      path: `${path}/devices/dashboard`,
      title: "DASHBOARD",
    },
    {
      path: `${path}/anomalies`,
      title: "PRESSURE ANOMALIES",
    },
    {
      path: `${path}/surveys`,
      title: "SURVEYS",
    },
  ];

  const navigation: JSX.Element = (
    <Navigation>
      {tabs.map((tab: any) => {
        return (
          <NavTab
            key={uuidv4()}
            to={tab.path}
            activeClassName="active"
            exact={tab.exact}
          >
            {tab.title}
          </NavTab>
        );
      })}
    </Navigation>
  );

  return (
    <Page>
      <Header />
      {navigation}

      <Switch>
        <Route path={`${path}/users`} component={UsersTable} exact />
        <Route path={`${path}/users/new`} component={CreateUserForm} />
        <Route path={`${path}/users/:id`} component={UpdateUserForm} />
        <Route path={`${path}/groups`} component={Groups} exact />
        <Route path={`${path}/groups/new`} component={GroupCreateForm} />
        <Route path={`${path}/groups/:id`} component={GroupUpdateForm} />
        <Route path={`${path}/licenses`} component={Licenses} exact />
        <Route path={`${path}/licenses/groups`} component={GroupLicensesForm} />
        <Route path={`${path}/scale/keys`} component={ScaleKeys} />
        <Route
          path={`${path}/scale/minimum-weight`}
          component={ScaleMinimumWeights}
        />
        <Route path={`${path}/scale/firmwares`} component={ScaleFirmwares} />
        <Route path={`${path}/devices`} component={Devices} exact />
        <Route path={`${path}/devices/tasks`} component={DeviceTasks} />
        <Route
          path={`${path}/devices/pressure-data`}
          component={DevicePressureData}
        />
        <Route
          path={`${path}/devices/device-management`}
          component={DeviceManagement}
          exact
        />
        <Route
          path={`${path}/devices/device-management/hg/:id`}
          component={EditHGDeviceForm}
        />
        <Route
          path={`${path}/devices/device-management/retrofit-light/:id`}
          component={EditRetrofitLightDeviceForm}
        />
        <Route path={`${path}/devices/dashboard`} component={Dashboard} exact />
        <Route path={`${path}/anomalies`} component={PressureAnomalies} exact />
        <Route path={`${path}/surveys`} component={SurveysAdminPage} exact />
        <Redirect from={`${path}`} to={`${path}/users`} exact />
      </Switch>
    </Page>
  );
};

export default Admin;
